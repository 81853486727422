<aside #menu [ngClass]="{ 'open-sidebar': toggleSide }" class="sidebarMenu">
  <div class="sidebar-content">
    <a [routerLink]="['/admin']" class="logo">
      <img
        alt=""
        class="desktop-logo"
        src="../../../../assets/images/eiac_logo.png"
      />
      <img
        alt=""
        class="mop-logo"
        src="../../../../assets/images/eiac_logo.png"
      />
    </a>
    <div class="menu-items">
      <ul class="nav-items">
        <li *ngFor="let item of menuItems">
          <ng-container *ngIf="item.is_menu">
            <div
              (click)="closeSubMenus(item)"
              *ngIf="!item.link"
              [class.active]="item.openSub"
              class="menu-parent"
            >
              <div
                [innerHtml]="sanitizerSVG(item.icon_menu)"
                class="menu-icon"
              ></div>
              <span>{{ item.name }}</span>
              <mat-icon
                *ngIf="
                  item.childParent && item.childParent.length && toggleSide
                "
                class="ml-auto"
                matSuffix
              >{{ item.openSub ? 'expand_less' : 'expand_more' }}</mat-icon>
            </div>
            <ul
              *ngIf="item.childParent && item.childParent.length"
              [ngClass]="{ 'sub-menu-container': item.openSub }"
            >
              <ng-container *ngFor="let sub of item.childParent">
                <li *ngIf="sub.is_menu" class="sub-menu">
                  <a [routerLinkActive]="['active']" [routerLink]="[sub.link]">
                    <span>{{ sub.name }}</span>
                  </a>
                </li>
              </ng-container>
            </ul>
            <a
              (click)="closeSubMenus(item)"
              *ngIf="item.link"
              [routerLinkActive]="'active'"
              [routerLink]="item.link"
            >
              <div
                [innerHtml]="sanitizerSVG(item.icon_menu)"
                class="menu-icon"
              ></div>
              <span>{{ item.name }}</span>
            </a>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
  <div (click)="sidebarTrigger.emit()" *ngIf="toggleSide" class="close-icon">
    <svg height="17" viewBox="0 0 17 17" width="17">
      <path
        d="M967.057,548.363l-6.847-6.849-6.847,6.849a.969.969,0,0,1-1.369-1.371l6.849-6.845-6.849-6.849a.967.967,0,0,1,1.369-1.366l6.847,6.847,6.847-6.847a.968.968,0,0,1,1.369,0,.957.957,0,0,1,.011,1.354l-.011.012-6.849,6.849,6.849,6.845a.968.968,0,0,1,0,1.369l0,0a.965.965,0,0,1-.678.286A.978.978,0,0,1,967.057,548.363Z"
        fill="#f0f0f0"
        id="close-menu-btn"
        transform="translate(-951.712 -531.648)"
      />
    </svg>
  </div>
</aside>
