<section class="create payment">
  <div class="create_title">Pay Fees</div>
  <div cdkFocusInitial class="close-dialog" mat-dialog-close>
    <svg
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        data-name="Path 7160"
        fill="#0c2e56"
        id="Path_7160"
        transform="translate(-5 -5)"
      />
    </svg>
  </div>
  <mat-dialog-content>
    <div class="create_content">
      <div class="payment_paymentDetails">
        <!-- <div class="payment_paymentDetails-header">Payment Details</div> -->
        <div class="d-flex align-items-center justify-content-between">
          <div class="payment_paymentDetails-header">Payment Details</div>
          <div class="">
            <!-- <button mat-raised-button class="mat-grey small-btn mr-2 mb-2 w-100"
              [class.loading]='loading'
              [disabled]='loading'
              (click)="downloadInvoiseDetails(data.data.my_enrollment_token)">
              <mat-icon>file_download</mat-icon> Training Invoise
            </button> -->
          </div>
        </div>
        <div class="payment_paymentDetails-payItem">
          <div class="row">
            <div class="col-sm-6">Training Fees</div>
            <div class="col-sm-6">
              <div class="price">
                <span *ngIf="data.data.tc_type == 0">{{ data.data.course_fees_for_individuals }}</span>
                <span *ngIf="data.data.tc_type == 1">{{ data.data.course_fees_for_group }}</span>
                <span class="currency">{{data.data.currency_name}}</span>
              </div>
            </div>
          </div>
        </div>
        <app-files-viewer *ngIf="data?.data?.trainee_invoice_file?.name"
          [files]="[data?.data?.trainee_invoice_file]"
        ></app-files-viewer>
        <!-- <div class="payment_paymentDetails-payItem vat">
          <div class="row">
            <div class="col-sm-6">Vat (3.5%)</div>
            <div class="col-sm-6">
              <div class="price">
                <span *ngIf="data.data.tc_type == 0">{{ (data.data.course_fees_for_individuals * 3.5) / 100 }}</span>
                <span *ngIf="data.data.tc_type == 1">{{ (data.data.course_fees_for_group * 3.5) / 100 }}</span>
                <span class="currency">EGP</span>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <hr/> -->
        <!-- <div class="payment_paymentDetails-total">
          <div class="row">
            <div class="col-sm-6">Total Fees</div>
            <div class="col-sm-6">
              <div class="price">
                <span *ngIf="data.data.tc_type == 0">
                  {{
                    (data.data.course_fees_for_individuals * 3.5) / 100 +
                    data.data.course_fees_for_individuals
                    }}
                </span>
                <span *ngIf="data.data.tc_type == 1">
                  {{
                    (data.data.course_fees_for_group * 3.5) / 100 +
                    data.data.course_fees_for_group
                    }}
                </span>
                <span class="currency">EGP</span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="payment_paymentMethods">
        <div class="payment_paymentMethods-header">Payment Methods</div>
        <div class="payment_paymentMethods-subheader">
          You can pay us, by using one of the following methods:
        </div>
        <ng-container *ngFor="let payment of paymentOptions">
          <ng-container *ngIf="payment.option_token == 'ABt4dzdAHfk27ShBNt8gu9CjWp1vzPkKcfc8Qje'">
            <div class="payment_paymentMethods-methods my-2">
              <div class="row">
                <div class="col-sm-12">
                  <div class="method-logo">
                    <div class="logo">
                      <svg
                        height="55.002"
                        viewBox="0 0 46.998 55.002"
                        width="46.998"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.481,55H3.476a1.534,1.534,0,0,1-1.533-1.527V50.488a1.534,1.534,0,0,1,1.533-1.529h1.6v-2.9a1.647,1.647,0,0,1,1.65-1.645h.36V31.2h-.36a1.652,1.652,0,0,1-1.65-1.65v-2.9H3.849a1.9,1.9,0,0,1-1.214-3.363L15.077,13a7.379,7.379,0,0,1-.351-.895H9.78V14a1.35,1.35,0,0,1-2.163,1.074L.53,9.7a1.349,1.349,0,0,1,0-2.145L7.617,2.2A1.348,1.348,0,0,1,9.78,3.266V5.154h5.432a9.236,9.236,0,0,1,16.57,0h5.436V3.266A1.348,1.348,0,0,1,39.381,2.2l7.082,5.363a1.345,1.345,0,0,1,0,2.145l-7.086,5.369A1.348,1.348,0,0,1,37.218,14V12.109H32.271a8.082,8.082,0,0,1-.351.895L44.363,23.293a1.9,1.9,0,0,1-1.214,3.363H41.926v2.9a1.654,1.654,0,0,1-1.65,1.65h-.359V44.418h.359a1.649,1.649,0,0,1,1.65,1.645v2.9h1.6a1.53,1.53,0,0,1,1.529,1.529v2.986A1.53,1.53,0,0,1,43.522,55Zm27.96-1.609V50.574H3.557v2.818H43.441Zm-9.236-8.975h4.1V31.2h-4.1Zm-12.757,0h4.1V31.2h-4.1Zm-12.752,0h4.1V31.2H8.7ZM6.686,29.553a.033.033,0,0,0,.036.035H40.275a.033.033,0,0,0,.036-.035v-2.9H6.686Zm-3.021-5.02a.272.272,0,0,0-.085.32.269.269,0,0,0,.27.193h39.3a.289.289,0,0,0,.185-.514l-12.213-10.1a9.233,9.233,0,0,1-15.243,0ZM15.873,9.227A7.624,7.624,0,1,0,23.5,1.615,7.627,7.627,0,0,0,15.873,9.227Zm6.817,4.951v-.619a4.178,4.178,0,0,1-2.136-1.225.808.808,0,0,1,1.2-1.088,2.613,2.613,0,0,0,1.92.811,1.317,1.317,0,0,0,1.358-.9c.108-.611-.67-.967-1.012-1.092-1.115-.414-2.109-.855-2.149-.873a.719.719,0,0,1-.112-.059,2.2,2.2,0,0,1-.98-2.18,2.462,2.462,0,0,1,1.916-2.016V4.322a.809.809,0,1,1,1.619,0v.584a4.554,4.554,0,0,1,1.817.854A.808.808,0,0,1,25.091,7a2.491,2.491,0,0,0-2.078-.477.814.814,0,0,0-.643.656.592.592,0,0,0,.225.566c.238.1,1.079.467,1.983.8,1.767.652,2.212,1.92,2.037,2.891a2.783,2.783,0,0,1-2.307,2.168v.57a.809.809,0,1,1-1.619,0Z"
                          fill="#fff"
                          id="wire-transfer"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="method-info">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="header">Bank Transfer</div>
                      </div>
                      <div class="col-sm-12">
                        <div class="sub-header">
                          Please use the following details below to transfer the
                          total amount
                          <span class="currency"
                          >{{ data.data.course_fees_for_individuals }} {{data.data.currency_name}}</span
                          >
                          , Once you made a transfer; kindly upload the payment
                          proof here.
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="method-details">
                          <!-- <div class="item">
                            <div class="row">
                              <div class="col-sm-4">IBAN</div>
                              <div class="col-sm-8 details">
                                EG800002000156789012345180002
                              </div>
                            </div>
                          </div>
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">Account Number</div>
                              <div class="col-sm-8 details">89012345180002</div>
                            </div>
                          </div>
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">SWIFT Code</div>
                              <div class="col-sm-8 details">NBEGEGCX196</div>
                            </div>
                          </div>
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">Account Holder Name</div>
                              <div class="col-sm-8 details">
                                GCC Accreditation Center
                              </div>
                            </div>
                          </div> -->
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">Name</div>
                              <div class="col-sm-8 details">
                                GCC Accreditation Center
                              </div>
                            </div>
                          </div>
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">Bank</div>
                              <div class="col-sm-8 details">
                                Al Rajhi Bank
                              </div>
                            </div>
                          </div>
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">Branch</div>
                              <div class="col-sm-8 details">
                                Riyadh Main Branch, Saudi Arabia
                              </div>
                            </div>
                          </div>
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">Account No IBAN</div>
                              <div class="col-sm-8 details">SA2880000344608010899776 (SAR)</div>
                            </div>
                          </div>
                          <div class="item">
                            <div class="row">
                              <div class="col-sm-4">SWIFT Code</div>
                              <div class="col-sm-8 details">RJHISARI</div>
                            </div>
                          </div>
                          <div class="action-btn">
                            <div class="row">
                              <div class="col-sm-12 question">
                                Have you completed bank transfer?
                              </div>
                              <div class="col-sm-12">
                                <button
                                  (click)="onUploadProof()"
                                  class="btn customBtn"
                                  [class.loading]="loading"
                                  [disabled]="loading"
                                  color="primary"
                                  mat-raised-button
                                >
                                  <span>
                                    <svg
                                      height="18"
                                      viewBox="0 0 18.996 18"
                                      width="18.996"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12.113,18a1.976,1.976,0,0,1-1.967-1.963V14.588a.26.26,0,0,0-.078-.2.256.256,0,0,0-.193-.072H2.649L2.726,3.064h7.71a4.748,4.748,0,0,0-.1.947,4.512,4.512,0,0,0,.42,1.914H7.6a.287.287,0,0,0,0,.574h3.47a4.541,4.541,0,0,0,3.007,2.055v7.488A1.962,1.962,0,0,1,12.123,18ZM4.491,11.924A1.009,1.009,0,1,0,5.5,10.953.992.992,0,0,0,4.491,11.924Zm2.823.02a.286.286,0,0,0,.285.285h4.365a.286.286,0,0,0,.285-.285.282.282,0,0,0-.285-.285H7.6A.282.282,0,0,0,7.313,11.943ZM4.491,9.076A1.009,1.009,0,1,0,5.5,8.105.992.992,0,0,0,4.491,9.076Zm2.823,0a.282.282,0,0,0,.285.285h4.365a.282.282,0,0,0,.285-.285.286.286,0,0,0-.285-.285H7.6A.286.286,0,0,0,7.313,9.076ZM4.491,6.234A1.008,1.008,0,1,0,5.5,5.258.989.989,0,0,0,4.491,6.234ZM1.962,18A1.963,1.963,0,0,1,0,16.043V14.887H9.576v1.15a2.515,2.515,0,0,0,.744,1.789,1.637,1.637,0,0,0,.193.174ZM5.07,11.924a.434.434,0,1,1,.431.4A.416.416,0,0,1,5.07,11.924Zm0-2.848a.434.434,0,1,1,.431.4A.42.42,0,0,1,5.07,9.076Zm5.858-5.045a4.034,4.034,0,1,1,4.036,4.035A4.036,4.036,0,0,1,10.929,4.031ZM13.6,5.09a1.345,1.345,0,0,0,1.024,1.3v.391a.288.288,0,0,0,.575,0V6.408a1.343,1.343,0,0,0-.256-2.662A.773.773,0,0,1,14.888,2.2h.029a.162.162,0,0,0,.043,0,.775.775,0,0,1,.759.773.285.285,0,0,0,.57,0A1.343,1.343,0,0,0,15.2,1.652V1.3a.288.288,0,1,0-.575,0v.357a1.347,1.347,0,0,0,.319,2.654.773.773,0,1,1-.773.773.285.285,0,0,0-.57,0ZM5.07,6.234a.434.434,0,1,1,.431.4A.416.416,0,0,1,5.07,6.234Z"
                                        fill="#fff"
                                        id="invoice"
                                      />
                                    </svg>
                                    Upload Proof</span
                                  >
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="payment.option_token == 'ABtdzdAHHrtrBNt8gu9CjWp1vzPkKcfc8Qje'">
            <div class="payment_paymentMethods-methods my-2">
              <div class="row">
                <div class="col-sm-12 d-flex align-items-center justify-content-between">
                  <div class="method-logo float-none h-auto">
                    <div>
                      <!-- <img alt="GAC" class="w-100" src="./assets/images/hyper-pay.png"/> -->
                      <img alt="GAC" class="w-100" src="./assets/images/online_payment_red_icon.png"/>
                      <!-- <svg
                        height="56"
                        id="efinnce-lofo-icon"
                        viewBox="0 0 65 56"
                        width="65"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          data-name="Group 398"
                          id="Group_398"
                          transform="translate(0 41.807)"
                        >
                          <path
                            d="M151.407,126.74c0,.474-.352.979-1.338.979H146.96c-.765,0-1.181-.272-1.315-.872l6.266-1.43c1.113-.233,1.678-1.12,1.678-2.626,0-1.411-.612-3.085-3.521-3.085h-3.108c-2.294,0-3.521,1.223-3.521,3.529v3.116c0,2.294,1.216,3.525,3.521,3.525h3.108c2.235,0,3.521-1.342,3.521-3.681v-.18l-2.183.627Zm-5.811-3.506c0-.956.409-1.368,1.366-1.368h3.108c.959,0,1.366.414,1.366,1.368,0,.038,0,.046-.041.061L145.6,124.63Z"
                            data-name="Path 1036"
                            fill="#fff"
                            id="Path_1036"
                            transform="translate(-88.59 -115.705)"
                          />
                          <path
                            d="M1.334,112.769v.474H0v2.16H1.334v8.03H3.49V115.4H5.708v-2.16H3.49v-.474c0-.959.405-1.373,1.361-1.373h1.3V109.24h-1.3C2.55,109.24,1.334,110.459,1.334,112.769Z"
                            data-name="Path 1037"
                            fill="#fff"
                            id="Path_1037"
                            transform="translate(0 -109.24)"
                          />
                          <path
                            d="M19.4,111.22a1.3,1.3,0,1,0,1.3,1.3A1.3,1.3,0,0,0,19.4,111.22Z"
                            data-name="Path 1038"
                            fill="#fff"
                            id="Path_1038"
                            transform="translate(-11.18 -110.463)"
                          />
                          <rect
                            data-name="Rectangle 20"
                            fill="#fff"
                            height="10.169"
                            id="Rectangle_20"
                            transform="translate(7.149 4.003)"
                            width="2.156"
                          />
                          <path
                            d="M32.2,119.71H26.9v10.169h2.156v-8.03H32.2c1.866,0,2.7.833,2.7,2.7v5.306h2.156v-5.285C37.05,121.35,35.417,119.71,32.2,119.71Z"
                            data-name="Path 1039"
                            fill="#fff"
                            id="Path_1039"
                            transform="translate(-16.614 -115.707)"
                          />
                          <path
                            d="M62.669,119.71H59.561c-2.294,0-3.521,1.223-3.521,3.529v.405l2.156-.414c0-.956.409-1.368,1.366-1.368H62.67c.765,0,1.185.272,1.315.872l-6.267,1.431c-1.113.233-1.678,1.12-1.678,2.626,0,1.407.612,3.081,3.521,3.081h3.108c2.294,0,3.521-1.219,3.521-3.525v-3.116C66.19,120.933,64.974,119.71,62.669,119.71Zm1.385,6.644c0,.959-.405,1.368-1.366,1.368H59.561c-.956,0-1.366-.409-1.366-1.368,0-.041,0-.046.046-.061l5.813-1.338Z"
                            data-name="Path 1040"
                            fill="#fff"
                            id="Path_1040"
                            transform="translate(-34.611 -115.707)"
                          />
                          <path
                            d="M90.489,119.71h-5.3v10.169h2.157v-8.03h3.143c1.866,0,2.676.833,2.676,2.7v5.306h2.156v-5.285C95.34,121.35,93.707,119.71,90.489,119.71Z"
                            data-name="Path 1041"
                            fill="#fff"
                            id="Path_1041"
                            transform="translate(-52.614 -115.707)"
                          />
                          <path
                            d="M120.959,119.71h-3.108c-2.294,0-3.521,1.223-3.521,3.529v3.116c0,2.294,1.216,3.525,3.521,3.525h3.108c2.294,0,3.521-1.219,3.521-3.525v-.417h-2.156v.414c0,.959-.405,1.368-1.366,1.368H117.85c-.956,0-1.366-.409-1.366-1.368v-3.116c0-.956.409-1.368,1.366-1.368h3.108c.959,0,1.366.414,1.366,1.368v.409h2.156v-.414C124.48,120.933,123.264,119.71,120.959,119.71Z"
                            data-name="Path 1042"
                            fill="#fff"
                            id="Path_1042"
                            transform="translate(-70.611 -115.707)"
                          />
                        </g>
                        <path
                          d="M59.34,6.782c9.6-1.242,15.4,2.634,8.593,8.682-7.48,6.648-23.144,11.3-23.944,11.473s-.922-.879,1.105-1.6a76.215,76.215,0,0,0,10.89-5.456c7.14-4.205,8.983-10.093,5.469-11.113-3.012-.875-10.908,2.218-17.081,9.9-7.278,9.065-6.238,18.636,4.922,16.5,11.8-2.244,20.72-11.889,21.691-13a6.055,6.055,0,0,0,1.53-4.068V0H31.92V26.137C34.949,18.075,44.815,8.651,59.34,6.782Z"
                          data-name="Path 1065"
                          fill="#fff"
                          id="Path_1065"
                          transform="translate(-19.717)"
                        />
                      </svg> -->
                    </div>
                  </div>
                  <div class="method-info">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="header">Online Payment</div>
                      </div>
                      <div class="col-sm-12">
                        <div class="sub-header">
                          Pay the required amount easily through Online Payment
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="method-details">
                          <div class="action-btn">
                            <div class="row">
                              <div class="col-sm-12">
                                <button
                                  [class.loading]="loading"
                                  [disabled]="loading"
                                  class="btn customBtn"
                                  color="primary"
                                  (click)="onEfinance()"
                                  mat-raised-button
                                >
                                  <span> Pay Online </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="payment.option_token == 'ABtdzdAHH8k27ShBNt8gu9CjWp1vzPkKcfc8Qje'">
            <div class="payment_paymentMethods-methods my-2">
              <div class="row">
                <div class="col-sm-12 d-flex align-items-center justify-content-between">
                  <div class="method-logo float-none h-auto">
                    <div>
                      <!-- <img src="./assets/images/egac-white.png" alt="egac" class="p-1"/> -->
                      <img alt="GAC" class="w-100" src="./assets/images/gac_logo.svg"/>
                    </div>
                  </div>
                  <div class="method-info">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="header">Pay at GAC Office</div>
                      </div>
                      <div class="col-sm-12">
                        <div class="sub-header">
                          You can pay by visiting us at the head office and pay with
                          your Debit / Credit Card.
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="method-details">
                          <div class="action-btn">
                            <div class="row">
                              <div class="col-sm-12">
                                <button
                                  [class.loading]="loading"
                                  [disabled]="loading"
                                  class="btn customBtn"
                                  color="primary"
                                  (click)="onOfficePay()"
                                  mat-raised-button
                                >
                                  <span> Pay </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>


      </div>
    </div>
    <form class="d-none" id="redirectToPay" ngNoForm [action]="payment_gateway_url" method="post">
      <input name="SenderID" [(ngModel)]="SenderID" [value]="SenderID">
      <input name="RandomSecret" [(ngModel)]="RandomSecret" [value]="RandomSecret">
      <input name="RequestObject" [(ngModel)]="RequestObject" [value]="RequestObject">
      <input name="HasedRequestObject" [(ngModel)]="HasedRequestObject" [value]="HasedRequestObject">
      <button type="submit" value="submit"></button>
    </form>
  </mat-dialog-content>
</section>
