<div class="header">
  <div class="app-sidebar__toggle">
    <a (click)="sidebarTrigger.emit()" class="open-toggle">
      <svg height="19" viewBox="0 0 20.001 19" width="20.001">
        <path
          d="M2157.5,3082a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,1,1,0,3Zm0-8a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,1,1,0,3Zm0-8a1.5,1.5,0,0,1,0-3h17a1.5,1.5,0,0,1,0,3Z"
          fill="#8F151A"
          id="menu-btn"
          transform="translate(-2156 -3063)"
        />
      </svg>
    </a>
  </div>
  <div class="logo-mobile">
    <img src="../../../../assets/images/eiac_logo.png"/>
  </div>
  <div
    class="header-app-btns d-flex align-items-center justify-content-between"
  >
  <div>
    <a (click)="changeLanguage()" class="lang-btn" *ngIf="showLocalizationIcon">
        <img src="./assets/images/icons/language-icon.svg" alt="">
        <span>{{ 'general.language' | translate }}</span>
    </a>
  </div>
    <div class="header-part2 d-flex align-items-center">
      <div class="notification-btn mr-4">
        <span (click)="openTasksPanel()">
          <span [class.seen]="allTasksIsSeen" class="tasks-count"></span>
          <img
            [class.swing]="!allTasksIsSeen"
            alt=""
            src="../../../../assets/images/icons/tasks-icon.svg"
          />
        </span>
        <div [ngClass]="{ active: tasksMenu }" class="notification-panel">
          <div class="notification-header">
            <h3>{{ 'general.Tasks' | translate }}</h3>
          </div>
          <div *ngIf="tasksLoading" class="dataLoading">
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div>
          <div [hidden]="tasksLoading">
            <div
              *ngIf="!tasksList.length"
              class="no-data text-center px-2 py-5"
            >
            {{ 'general.You have no Tasks' | translate }}!
            </div>
            <div
              (scrolled)="getTasksNextPage()"
              *ngIf="tasksList.length"
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="tasksPageCount"
              [scrollWindow]="false"
              class="notification-list"
              infiniteScroll
            >
              <div
                (click)="tasksMenu = false"
                *ngFor="let task of tasksList"
                [ngClass]="{ new: !task?.status }"
              >
                <!-- <a
                  *ngIf="task?.task_text"
                  routerLink="/admin/{{ task.task_link }}/{{
                    task.reference_token
                  }}"
                > -->
                <a
                  *ngIf="task?.task_text"
                  (click)="navigateTask(task)"

                >
                  <p class="title">{{ task?.task_text }}</p>
                  <span class="date">{{ task?.created_at | unixDate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="notification-btn">
        <span (click)="openNotificationPanel()">
          <span
            [class.seen]="allNotificationIsSeen"
            class="notification-count"
          ></span>
          <img
            [class.swing]="!allNotificationIsSeen"
            alt=""
            src="../../../../assets/images/icons/notifications-icon.svg"
          />
        </span>
        <div
          [ngClass]="{ active: notificationMenu }"
          class="notification-panel"
        >
          <div [class.loading]="notifLoading" class="notification-header">
            <h3>{{ 'general.Notification' | translate }}</h3>
          </div>
          <div *ngIf="notifLoading" class="dataLoading">
            <mat-spinner class="m-auto" diameter="50"></mat-spinner>
          </div>
          <div [hidden]="notifLoading">
            <div
              *ngIf="!notificationsList.length"
              class="no-data text-center px-2 py-5"
            >
            {{ 'general.You have no notifications' | translate }}!
            </div>
            <div
              (scrolled)="getNextPage()"
              *ngIf="notificationsList.length"
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="pageCount"
              [scrollWindow]="false"
              class="notification-list"
              infiniteScroll
            >
              <div
                (click)="notificationMenu = false"
                *ngFor="let notif of notificationsList"
                [ngClass]="{ new: !notif?.is_seen }"
              >
                <a *ngIf="notif?.message_text" class="d-cusror">
                  <p class="title">{{ notif?.message_text }}</p>
                  <span class="date">{{ notif?.created_at | unixDate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        (click)="userMenu = !userMenu; notificationMenu = false"
        class="userAccount"
        id="userAccountDrop"
      >
        <div class="profile-image">
          <img
            (error)="currentUser.profile_img = ''"
            *ngIf="currentUser.profile_img"
            [src]="currentUser.profile_img"
          />
          <span
            *ngIf="!currentUser.profile_img"
            [innerHTML]="currentUser.fullName | shortName"
          ></span>
        </div>
        <div class="user-name">
          <span class="mr-2">
            {{ 'header.Hello' | translate }},
            <span class="avatar-name">{{ currentUser.fullName }}</span>
          </span>
          <svg *ngIf="!userMenu" height="5" viewBox="0 0 8 5" width="8">
            <path
              d="M-2.313-6.729.75-3.627l3.063-3.1.937.949-4,4.051-4-4.051Z"
              fill="#999"
              id="arrow-down"
              transform="translate(3.25 6.729)"
            />
          </svg>
          <svg *ngIf="userMenu" height="5" viewBox="0 0 8 5" width="8">
            <path
              d="M-2.313-1.729.75-4.83l3.063,3.1.937-.949-4-4.051-4,4.051Z"
              fill="#999"
              id="arrow-down"
              transform="translate(3.25 6.729)"
            />
          </svg>
        </div>
        <div [ngClass]="{ active: userMenu }" class="custom-dropdown-menu">
          <ul>
            <li>
              <a
                [routerLink]="[
                  'UserManagement/MyProfile',
                  currentUser.user_token
                ]"
              >
                <svg height="17" viewBox="0 0 14 17" width="14">
                  <path
                    d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                    fill="#999999"
                    id="my-profile-icon"
                  />
                </svg>
                {{ 'auth.My-Profile' | translate }}
              </a>
            </li>
            <li>
              <a [routerLink]="['/ChangePassword' , userToken ]">
                <svg height="17" viewBox="0 0 14 17" width="14">
                  <path
                    d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                    fill="#999999"
                    id="my-profile-icon"
                  />
                </svg>
                {{ 'auth.Update-password' | translate }}
              </a>
            </li>
            <!-- <li>
              <a (click)="onInitNotification()">
                <svg width="14" height="17" viewBox="0 0 14 17">
                  <path
                    id="my-profile-icon"
                    d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                    fill="#999999"
                  />
                </svg>
                Notification
              </a>
            </li> -->
            <li>
              <a (click)="logout()">
                <svg height="17.006" viewBox="0 0 17.001 17.006" width="17.001">
                  <path
                    d="M6223.19,12103h-13.4a1.815,1.815,0,0,1-1.8-1.808v-13.383a1.815,1.815,0,0,1,1.811-1.808h13.4a1.817,1.817,0,0,1,1.792,1.808v4.146l-1.659-1.559v-2.74h-13.687v13.575h13.687v-2.625l1.659-1.461v4.165a1.766,1.766,0,0,1-.529,1.267,1.69,1.69,0,0,1-1.123.43C6223.3,12103.006,6223.243,12103,6223.19,12103Zm-4.514-5.039,2.691-2.688h-9.562v-1.54h9.562l-2.691-2.691,1.167-1.169,4.52,4.629-4.52,4.629Z"
                    fill="#999"
                    id="logout-icon"
                    transform="translate(-6208 -12086)"
                  />
                </svg>
                {{ 'header.SignOut' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="userMenu || notificationMenu || tasksMenu" id="popOverlay"></div>
