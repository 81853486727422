import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AllTrainingListService } from 'src/app/features/training/services/all-training-list.service';

@Component({
  selector: 'app-efinance-form',
  templateUrl: './efinance-form.component.html',
  styles: [
  ]
})
export class EfinanceFormComponent implements OnInit {

  dataLoading = false;
  paymentProvider = null;
  redirectLink = null;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EfinanceFormComponent>,
    private toastr: ToastrService,
    private allTrainingListService: AllTrainingListService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.paymentProvider = this.data.paymentProvider;
    if(this.paymentProvider) {
      this.getDataToPaymentGetway();
    }
  }


  getDataToPaymentGetway() {
    this.dataLoading = true;
    const data = {
      type: this.data.type,
      payment_provider: this.paymentProvider,
      payment_token: this.data.payment_token,
    }
    this.allTrainingListService.getDataToPaymentGetway(data).subscribe(
      (res) => {
        this.redirectLink = res.paymentConfirmationDomain + 'paymentconfirmation/' + res.paymentConfirmationToken;
        // this.redirectLink =  'http://localhost:4200//paymentconfirmation/' + res.paymentConfirmationToken;
        this.loadJsScript(res.paymentGatewayURL);
        this.dataLoading = false;
      },
      (err) => {
        this.dataLoading = false;
        this.toastr.success(err.message);
      }
    );

  }

  public loadJsScript(src: string): HTMLScriptElement {
    const oldScript = document.getElementById('e-payment-script');

    const script = this.renderer.createElement('script');
    script.id = 'e-payment-script';
    script.src =  src;
    if(oldScript) {
      this.renderer.removeChild(this.document.body, oldScript);
    }
      // this.renderer.destroyNode(oldForm);
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  changeMethod(id) {
    if(id != this.paymentProvider) {
      const oldScript = document.getElementById('e-payment-script');
      if(oldScript) {
        this.renderer.removeChild(this.document.body, oldScript);
      }
      const oldForm = document.getElementById('eFinanceForm');

      if(oldForm) {
        oldForm.remove()
      }
      this.dialogRef.close(id);
    }
    // if(!this.dataLoading) {
    //   const oldForm = document.getElementById('eFinanceForm');
    //   if(oldForm) {
    //     oldForm.remove()
    //   }
    //   this.paymentProvider = id;
    //   this.getDataToPaymentGetway();
    // }
  }

  closePayment() {
    // this.dialogRef.close(false);
    window.location.reload();
  }
}
