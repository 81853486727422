import {catchError} from 'rxjs/operators';
import {ApiService} from './../../../core/service/api.service';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private apiService: ApiService) {
  }

  getCountries() {
    return this.apiService
      .get(`${environment.apiUrl}/country/list`)
      .pipe(catchError(this.handleError));
  }

  getEgacStaff(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/egac_staff_list`, filter)
      .pipe(catchError(this.handleError));
  }

  getCabRepresentative(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab_representative_list`, filter)
      .pipe(catchError(this.handleError));
  }

  getCabsUsers(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cabs_list`, filter)
      .pipe(catchError(this.handleError));
  }

  getTrainees(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/trainees_list`, filter)
      .pipe(catchError(this.handleError));
  }

  getExternalStaff(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/egac_external_list`, filter)
      .pipe(catchError(this.handleError));
  }

  getCommittee(filter: any) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_committees`, filter)
      .pipe(catchError(this.handleError));
  }

  getCommitteeCategory(model) {
    const param = model.type;
    return this.apiService
      .get(`${environment.apiUrl}/api/system/get_committee_category/${param}`)
      .pipe(catchError(this.handleError));
  }
  getAllCommitteeCategory(model) {
    const param = model.type;
    return this.apiService
      .get(`${environment.apiUrl}/api/system/get_all_committee_category`)
      .pipe(catchError(this.handleError));
  }
  createCommittee(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_committee`, modal)
      .pipe(catchError(this.handleError));
  }

  editCommittee(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_committee`, modal)
      .pipe(catchError(this.handleError));
  }

  getCommitteeDetails(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/committee_details`, modal)
      .pipe(catchError(this.handleError));
  }

  getCommitteeMembers(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/committee_members`, modal)
      .pipe(catchError(this.handleError));
  }

  getCommitteeMemberFilter(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/committee_filter_members`, modal)
      .pipe(catchError(this.handleError));
  }

  addMemberToCommittee(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_committee_members`, modal)
      .pipe(catchError(this.handleError));
  }

  removeCommitteeMember(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/committee_remove_member`, modal)
      .pipe(catchError(this.handleError));
  }

  addNewAssign(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/assign_convener`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteCommittee(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/delete_committee`, modal)
      .pipe(catchError(this.handleError));
  }

  getUserConvener(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/search_users_convener`, modal)
      .pipe(catchError(this.handleError));
  }

  approveUser(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/approve_user`, modal)
      .pipe(catchError(this.handleError));
  }

  addEgacUser(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_egac_user`, modal)
      .pipe(catchError(this.handleError));
  }

  addCabsUser(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_cab_user`, modal)
      .pipe(catchError(this.handleError));
  }

  addCabRepredentative(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/register_cab_representative`, modal)
      .pipe(catchError(this.handleError));
  }

  addTrainee(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_trainees_user`, modal)
      .pipe(catchError(this.handleError));
  }

  addExternalUser(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_external_user`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteUser(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/delete_user`, modal)
      .pipe(catchError(this.handleError));
  }

  getAllRoles(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/all_roles`, modal)
      .pipe(catchError(this.handleError));
  }

  getAllUserToAssign(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/search_assign_user`, modal)
      .pipe(catchError(this.handleError));
  }

  assignUserToRole(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/assign_user_to_role`, modal)
      .pipe(catchError(this.handleError));
  }

  getAllRolePermissions(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/all_permissions_role`, modal)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }
}
