import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {AdminLayoutComponent} from './layout/admin-layout/admin-layout.component';
import {BasicLayoutComponent} from './layout/basic/basic-layout.component';
import {NoPermissionComponent} from './shared/components/no-permission/no-permission.component';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import { PaymentConfirmationPageComponent } from './shared/components/payment-confirmation-page/payment-confirmation-page.component';

const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'UserManagement',
        loadChildren: () =>
          import('./features/user-management/user-managment.module').then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/assessors/assessors.module').then(
            (m) => m.AssessorsModule
          ),
      },
      {
        path: 'CABs',
        loadChildren: () =>
          import('./features/cabs/cabs.module').then((m) => m.CabsModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/configuration/configuration.module').then(
            (m) => m.ConfigurationModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/documents/documents.module').then(
            (m) => m.DocumentsModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/assessors/assessors.module').then(
            (m) => m.AssessorsModule
          ),
      },
      {
        path: 'Training',
        loadChildren: () =>
          import('./features/training/training.module').then(
            (m) => m.TrainingModule
          ),
      },
      {
        path: 'annual_plan',
        loadChildren: () =>
          import('./features/annual-training/annual-training.module').then(
            (m) => m.AnnualTrainingModule
          ),
      },
      {
        path: 'instructors',
        loadChildren: () =>
          import('./features/instructors/instructors.module').then(
            (m) => m.InstructorsModule
          ),
      },
      {
        path: 'group-training',
        loadChildren: () =>
          import('./features/group-training/group-training.module').then(
            (m) => m.GroupTrainingModule
          ),
      },
      {
        path: 'group-training-requests',
        loadChildren: () =>
          import(
            './features/group-training-requests/group-training-requests.module'
            ).then((m) => m.GroupTrainingRequestsModule),
      },
      {
        path: 'accreditation-requests',
        loadChildren: () =>
          import(
            './features/cabs/modules/accreditation-requests/accreditation-requests.module'
            ).then((m) => m.AccreditionRequestsModule),
      },
      {
        path: 'accreditation-financial',
        loadChildren: () =>
          import(
            './features/cabs/modules/accreditation-financial/accreditation-financial.module'
            ).then((m) => m.AccreditationFinancialModule),
      },
      {
        path: 'assigned-cab-acc-requests',
        loadChildren: () =>
          import(
            './features/cabs/modules/assigned-cab-acc-requests/assigned-cab-acc-requests.module'
            ).then((m) => m.AssignedCabAccRequestsModule),
      },
      {path: 'no-permission', component: NoPermissionComponent},
    ],
  },
  {path: 'paymentconfirmation/:token', component: PaymentConfirmationPageComponent},
  {path: '**', component: PageNotFoundComponent},
  // otherwise redirect to home
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
