import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {UploadProofComponent} from '../upload-proof/upload-proof.component';
import Swal from 'sweetalert2';
import { AccreditationFinancialService } from 'src/app/features/cabs/modules/accreditation-financial/services/accreditation-financial.service';
import { AllTrainingListService } from 'src/app/features/training/services/all-training-list.service';
import { EfinanceFormComponent } from '../efinance-form/efinance-form.component';
import { saveAs } from 'file-saver';

@Component({
  templateUrl: './payment-modal.component.html',
  styles: [],
})
export class PaymentModalComponent implements OnInit {
  loading = false;

  HasedRequestObject: any = '';
  RandomSecret: any= '';
  RequestObject: any= '';
  SenderID: any= '';
  payment_gateway_url: any= '';
  dataLoading = false;
  paymentOptions: any = [];

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    private toastr: ToastrService,
    private accreditationFinancialService: AccreditationFinancialService,
    private allTrainingListService: AllTrainingListService,
  ) {
  }

  ngOnInit(): void {
    console.log(this.data);
    this.getPaymentOptions();
  }

  getPaymentOptions() {
    this.dataLoading = true;
    const model = {
      currency_token: this.data.data.currency_token
    };
    this.accreditationFinancialService.getPaymentOptions(model).subscribe(
      (res) => {
        this.dataLoading = false;
        this.paymentOptions = res.data;
        console.log(res);

      },
      (err) => {
        this.showError(err.message);
        this.dataLoading = false;
      }
    );

  }

  onUploadProof() {
    // const otherSkillData = {
    //   userToken: this.userToken,
    //   yearsOfExpList: this.yearsOfExpList,
    // };
    const dialogRef = this.dialog.open(UploadProofComponent, {
      width: '450px',
      data: this.data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showSuccess('Successfully Added');
        Swal.fire({
          title: 'Success',
          text: 'Your request has been successfully registered, and the finance manager will review your payment , Thank you',
          icon: 'success',
          confirmButtonText: 'Ok',
        });
        this.dialogRef.close(true);
      }
    });
  }

  onlinePay() {
    this.loading = true;
    const obj = {
      payment_token: this.data.courseToken,
      type: 0,   // 0 => Training , 1 => cab
    };
    this.accreditationFinancialService.onlinePayment(obj).subscribe((res) => {
      this.loading = false;
      this.HasedRequestObject = res.HasedRequestObject,
      this.RandomSecret = res.RandomSecret,
      this.RequestObject = res.RequestObject,
      this.SenderID = res.SenderID,
      this.payment_gateway_url = res.payment_gateway_url

      setTimeout(() => {
        let form: HTMLFormElement = <HTMLFormElement>document.getElementById('redirectToPay');
        form.submit();
      }, 40);
    },
    (err) => {
      this.loading = false;
      err.message.forEach(element => {
        this.showError(element.message);
      });
    });
  }

  onOfficePay() {
    Swal.fire({
      title: 'Are you sure',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        const model = {
          course_token: this.data.courseToken,
          payment_option: 2,
        };
        this.allTrainingListService.traineePayment(model).subscribe(
          (res) => {
            this.dialogRef.close(true);
            Swal.fire({
              title: 'Success',
              text: 'Your request has been successfully registered, Thank you',
              icon: 'success',
              confirmButtonText: 'Ok',
            });
            this.loading = false;
          },
          (err) => {
            // console.log(err);
            this.toastr.error(err.message);
            this.loading = false;
          }
        );
      }
    });
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }

  onEfinance (id = null) {
    const modle = {
      type: 0,   // training
      payment_token: this.data.courseToken,
      paymentProvider: id
    };
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(EfinanceFormComponent, {
      width: '800px',
      data: modle,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result) {
        this.onEfinance(result)
      }
    });
  }

  downloadInvoiseDetails(enrollment_token) {
    this.loading = true;
    const model = {
      enrollment_token: enrollment_token,
    }
    this.allTrainingListService.downloadTrainingInvoiseAddressAsPdf(model)
      .subscribe((data: Blob) => {
        this.loading = false;
        const blob = new Blob([data]);
        saveAs(blob, `Invoise Details.pdf`);
      });
  }
}
